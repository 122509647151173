import { VueConstructor, VNodeData } from 'vue'
import {
  absolutePosition,
  fixedSize,
  gridSize,
  gridPosition,
} from './style'
import { ComponentType } from './data'
import { PageBuilderComponent } from '@/components/definitions/pageBuilderComponent'
import { Option } from '@/models/options'
import { GetValueModel } from '@/store-dynamic/page'

// Define a bone
export interface bone {
  id: string;
  type: ComponentType;
  size: fixedSize | gridSize;
  position: absolutePosition | gridPosition;
  dependencies: string[];
  slot?: string;
}

// Define a slot
export interface slot {
  id: string;
  bones: bone[];
}

// Define a layout
export interface layout {
  id: string; // avec un id unique
  bones?: bone[]; // seulement les bones de la racine et les placer
}

// Define a skeleton
export interface skeleton {
  layout: layout; // Un layout
  slots: slot[]; // qui contient plusieurs slots
}

export enum OptionTypes {
  INPUT_STRING = 't_string',
  INPUT_NUMBER = 't_number',
  CHECKBOX = 't_boolean',
  INPUT_DATE = 't_date',
  SELECT = 't_select',
  MF = 't_mf',
  MF_SINGLE_KID = 't_mf_single_kid',
  COLOR_PICKER = 't_color_picker',
  COORDINATES_EDITOR = 't_coordinates_editor',
  TABS = 't_tabs',
  ITEM_LIST = 't_item_list'
}

export type ArrayElement<A> = A extends readonly (infer T)[]
  ? T // Base array type
  : A // Not an array

// Or serve as a validator for the options
export type UnwrapOption<T> = T extends Option<infer U> ? U : never
export type ComponentOptions = Record<string, Option<any>>
export type Options<T extends PageBuilderComponent> = T['options']
export type OptionsKeys<T extends PageBuilderComponent> = keyof Options<T>
export type OptionsValues<T extends PageBuilderComponent, P extends OptionsKeys<T>> = UnwrapOption<T['options'][P]>
// export type Properties<T extends PageBuilderComponent> = Record<OptionsKeys<T>, OptionsValues<T>>
export type Properties<T extends PageBuilderComponent> = { [P in OptionsKeys<T>]: OptionsValues<T, P> }

export interface ICustomStyle {
  [key: string]: any;
}

// Define a component
// eslint-disable-next-line
export interface component<T extends PageBuilderComponent> {
  id: string;
  type: ComponentType;
  name: string;

  // The style of the component
  // style: CSS.StandardProperties;

  // The data linked to the component
  data: any;

  dynamicProperties?: GetValueModel[];

  /**
   * Documentation de l'objet: https://fr.vuejs.org/v2/guide/render-function.html#Objet-de-donnees-dans-le-detail
   */
  node: VNodeData;

  // child
  components: string[] | any[];
  // components: Record<string | 'default', string[]>;
}

export type Rule = (value: unknown) => boolean | string
export type Validator = (...options: unknown[]) => Rule

export interface IPageBuilderComponent {
  name: string;
  fallback: VueConstructor;
  loader: VueConstructor | string;
  timeout: number;
  display: boolean;
  options: ComponentOptions;

  getComponent: () => VueConstructor | Promise<VueConstructor> | undefined;
}
