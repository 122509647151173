



























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KButton extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  hidden!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  disabled!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  text!: boolean

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  label!: string

  @Prop({
    type: String,
    required: false,
    default: '#fff',
  })
  bgColor!: string

  @Prop({
    type: String,
    required: false,
    default: '#000',
  })
  textColor!: string

  @Prop({
    type: String,
    required: false,
    default: '',
  })
  icon!: string

  @Prop({
    type: String,
    required: false,
    default: 'left',
  })
  iconPosition!: string
}
