































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Panel extends Vue {
  @Prop({ type: Boolean, default: false })
  hidden!: boolean

  @Prop({ type: Boolean, default: false })
  disabled!: boolean

  @Prop({ type: String, default: '' })
  tooltip!: string

  @Prop({ type: String, default: 'Panel' })
  title!: string

  @Prop({ type: Boolean, default: true })
  collapsible!: boolean

  @Prop({ type: Boolean, default: false })
  collapsed!: boolean

  isExpanded = false

  mounted() {
    this.isExpanded = !this.collapsed
  }
}
