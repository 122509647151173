












import Vue from 'vue'

export default Vue.extend({
  props: {
    icon:{
      type: String,
      required: true,
    },
    width:{
      type: Number,
      required: false,
      default: undefined
    },
    height:{
      type: Number,
      required: false,
      default: undefined
    },
    // size is the fallback value for either width and height
    size:{
      type: Number,
      required: false,
      default: undefined
    },
    color:{
      type: String,
      required: false,
      default: undefined
    },
  },
  computed: {
    computedWidth() {
      return this.width ?? this.size
    },

    computedHeight() {
      return this.height ?? this.size
    }
  }
})
