import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import {
  createString,
  createBoolean,
  createColor,
} from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'
import { component, ComponentOptions, OptionTypes } from '@/models/architecture'

export default class Button extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Bouton'

  description = 'Un simple bouton'

  icon = 'mdi-gesture-tap'

  category = Category.OTHER

  options: ComponentOptions = {
    label: createString('Texte', 'OK'),
    hidden: createBoolean('Caché'),
    disabled: createBoolean('Désactivé'),
    text: createBoolean('Transparent'),
    bgColor: createColor('Couleur du fond'),
    textColor: createColor('Couleur du texte', '#000'),
    icon: createString('Icône', ''),
    iconPosition: (item?: component<Button>) => ({
      label: 'Position de l\'icône',
      type: OptionTypes.SELECT,
      visible: item?.node.props?.icon !== '',
      default: 'left',
      options: [
        {
          value: 'left',
          text: 'Gauche',
        },
        {
          value: 'right',
          text: 'Droite',
        },
      ],
    }),
  }
}
