




import { Component } from 'vue-property-decorator'
import Email from '@/components/uiComponents/email'
import { UIComponent } from '@/components/uiComponents/ui-component'
import emailRender from '@/components/uiComponents/email/emailRender'

@Component({
  components: {
    emailRender,
  },
})
export default class EmailBuilder extends UIComponent<Email> {}
