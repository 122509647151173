








import { VueConstructor } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { component } from '@/models/architecture'

import { getDefinition } from '@/components/definitions'

@Component
export default class ComponentRenderer extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  data!: component<any>

  myComponent: VueConstructor | typeof import('*.vue') | undefined | null = null

  async mounted(): Promise<void> {
    console.log(`Starting to load component ${this.data.id}`)

    const Definition = getDefinition(this.data.type)

    if (Definition && Definition.getComponent) {
      this.myComponent = await Definition.getComponent()
    }
  }
}
