







import { Component } from 'vue-property-decorator'
import FormBase from '@/components/formBase.vue'
import KInput from '@/components/knitiv/kinput.vue'

@Component({
  inheritAttrs: false,
  components: {
    KInput,
  },
})
export default class KTextArea extends FormBase {}
