
import { Component, Vue } from 'vue-property-decorator'

@Component({
  // @ts-ignore
  functional: true,
  // @ts-ignore
  render(createElement, { props }) {
    return createElement(
      'div',
      props.rootItem.node,
      [
        createElement(
          'span',
          {},
          [
            'Emplacement dynamique pour composant',
          ],
        ),
      ],
    )
  },
})
export default class slotRender extends Vue {}
