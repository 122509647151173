

















import { Component, Inject } from 'vue-property-decorator'
import { VRow } from 'vuetify/lib'
import { UIComponent } from '@/components/uiComponents/ui-component'
import Columns from '@/components/uiComponents/columns'
import ChildDropZone from '@/components/ChildDropZone.vue'
import { ComponentType } from '@/models/data'

@Component({
  components: {
    ChildDropZone,
  },
})
export default class ColumnsBuilder extends UIComponent<Columns> {
  accept = [ComponentType.COLUMN]

  @Inject({
    default: {
      VRow,
    },
  }) readonly injected!: any

  get style() {
    return {
      margin: this.props.direction === 'row' ? '0 15px' : '15px 0',
    }
  }
}
