import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { form } from '@/utils/options/creator'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { placeholder, ...options } = form

export default class Checkbox extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'Case à cocher'

  icon = 'mdi-checkbox-marked'

  category = Category.INPUT

  options = {
    ...options,
  }
}
