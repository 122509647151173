




import { Component } from 'vue-property-decorator'

import { UIComponent } from '@/components/uiComponents/ui-component'
import JSON from '@/components/uiComponents/json'
import jsonRender from '@/components/uiComponents/json/jsonRender.vue'

@Component({
  components: {
    jsonRender,
  },
})
export default class JSONBuilder extends UIComponent<JSON> {}
