import ListLoader from '@/components/uiComponents/list/listLoader.vue'
import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'

export default class List extends PageBuilderSyncComponent {
  loader = ListLoader

  name = 'Liste'

  icon = 'mdi-format-list-bulleted-square'

  category = Category.DATABASE

  options = {}
}
