import { VueConstructor } from 'vue'
import { Vue } from 'vue-property-decorator'
import { ComponentOptions, IPageBuilderComponent, Properties } from '@/models/architecture'
import DefaultLoader from '@/components/display/defaultLoader'
import Loader from '@/components/display/loader'
import { ComponentType } from '@/models/data'
import ErrorBuilder from '@/components/uiComponents/defaultError/errorBuilder.vue'
import { Category } from '@/models/pages/pagebuilder'

export abstract class PageBuilderComponent implements IPageBuilderComponent {
  fallback = ErrorBuilder

  loader: VueConstructor | string = DefaultLoader

  display = true

  timeout = 5000

  abstract options: ComponentOptions

  abstract name: string;

  abstract icon: string;

  description = ''

  category = Category.OTHER

  abstract getComponent(): any;

  getLoader(): VueConstructor {
    return typeof this.loader === 'string' ? Loader(this.loader, {}) : this.loader
  }

  getOptionDefaults() {
    const { options } = this
    // eslint-disable-next-line
    return Object.entries(options).reduce(
      (acc: Properties<any>, [key, option]) => {
        // @ts-ignore
        const resolvedOption = option()
        // @ts-ignore
        acc[key] = resolvedOption.default
        return acc
      },
      {},
    )
  }

  // validateOptions (options: Record<string, unknown>): boolean {
  //   const optionsValidator = this.options
  //   const componentOptions = options
  //
  //   console.log('validating ', componentOptions, 'with', optionsValidator)
  //
  //   return Object.entries(optionsValidator).every(([validatorKey, validatorValue]) => {
  //     // check if the key is required and if present inside the component options
  //     if (validatorKey in componentOptions) {
  //       const componentValue = componentOptions[validatorKey]
  //       // Check if the value have the right type
  //       if (validatorValue.type === 'array' && Array.isArray(componentValue)) {
  //         return true
  //       }
  //       // eslint-disable-next-line
  //       if (typeof componentValue === validatorValue.type) {
  //         return true
  //       }
  //
  //       console.error(`Incorrect type. Expected "${validatorValue.type}", got "${typeof componentValue}"`)
  //       return false
  //     } else {
  //       // return false if the value was required
  //       if (validatorValue.required) {
  //         console.error(`La clé ${validatorKey} est requise mais n'a pas été trouvée`)
  //         return false
  //       }
  //       // true otherwise
  //       return true
  //     }
  //   })
  // }
}

export abstract class PageBuilderSyncComponent extends PageBuilderComponent {
  protected component!: VueConstructor

  abstract name: string;

  abstract options: ComponentOptions

  public constructor(component: VueConstructor) {
    super()
    this.component = component
  }

  getComponent(): VueConstructor {
    return this.component
  }
}

export abstract class PageBuilderAsyncComponent extends PageBuilderComponent {
  abstract component: Promise<typeof import('*.vue')>

  abstract name: string;

  abstract options: ComponentOptions

  abstract icon: string

  getComponent(): Promise<VueConstructor> | undefined {
    return this.component?.then((c) => c.default)
  }
}

export type PageBuilderComponents = Record<ComponentType, PageBuilderComponent>
export type PageBuilderVueComponents = Record<ComponentType, VueConstructor<Vue>>
export type PageBuilderAsyncComponents = Record<ComponentType, PageBuilderAsyncComponent>
export type PageBuilderRawDefinitions = Record<ComponentType, PageBuilderSyncComponent>
export type PageBuilderSyncComponents = Record<ComponentType, PageBuilderSyncComponent>
