
import { Component, Vue } from 'vue-property-decorator'
import {
  VAlert, VBtn, VCol, VRow, VSpacer,
} from 'vuetify/lib'

@Component({
  // @ts-ignore
  functional: true,
  // @ts-ignore
  render(createElement, { props }) {
    return createElement(
      VAlert,
      {
        props: {
          type: 'error',
        },
      },
      [
        createElement(
          VRow,
          {
            props: {
              align: 'center',
              'no-gutters': true,
            },
          },
          [
            createElement(
              VCol,
              {
                class: 'grow',
              },
              [
                `Chargement du composant ${props.rootItem.id} impossible`,
              ],
            ),
            createElement(
              VSpacer,
            ),
            createElement(
              VCol,
              {
                class: 'shrink',
              },
              [
                createElement(
                  VBtn,
                  {
                    props: {
                      color: 'info',
                      disabled: true,
                    },
                  },
                  [
                    'Détails',
                  ],
                ),
              ],
            ),
          ],
        ),
      ],
    )
  },
})
export default class errorRender extends Vue {}
