


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class UIImage extends Vue {
  @Prop({
    type: String,
    default: 'http://placehold.jp/100x50.png?text=Aucune%20image%20d%C3%A9finie',
  })
  src!: string

  @Prop({
    type: Boolean,
    default: false,
  })
  hidden!: boolean

  @Prop({
    type: Boolean,
    default: true,
  })
  contain!: boolean

  @Prop({
    type: Number,
    default: 50,
  })
  height!: number

  @Prop({
    type: String,
    default: '',
  })
  caption!: string

  @Prop({
    type: Boolean,
    default: false,
  })
  hideCaption!: boolean
}
