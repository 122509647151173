import { PageBuilderSyncComponent } from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { ComponentOptions, OptionTypes } from '@/models/architecture'

import { createSelect, form } from '@/utils/options/creator'

export default class PhoneInput extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Saisie de téléphone'

  description = 'Un simple bouton'

  icon = 'mdi-gesture-tap'

  category = Category.INPUT

  options: ComponentOptions = {
    ...form,
    mode: createSelect('Pays', 'auto', 'Change la façon dont est déterminée le mode du pays', {
      options: [
        {
          text: 'Automatique',
          value: 'auto',
        },
        {
          text: 'Personalisé',
          // @ts-ignore
          value: 'custom',
        },
      ],
    }),
    code: (item) => ({
      default: '',
      description: 'Le code du pays à afficher par défaut',
      disabled: false,
      label: 'Code pays',
      type: OptionTypes.INPUT_STRING,
      visible: item.node.props?.mode === 'custom',
    }),
  }
}
