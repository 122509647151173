










import { Component } from 'vue-property-decorator'
import ChildDropZone from '@/components/ChildDropZone.vue'
import { UIComponent } from '@/components/uiComponents/ui-component'
import Page from '@/components/uiComponents/page/index'

@Component({
  components: {
    ChildDropZone,
  },
})
export default class PageBuilder extends UIComponent<Page> {}
