












import { Component } from 'vue-property-decorator'
import ChildDropZone from '@/components/ChildDropZone.vue'
import { UIComponent } from '@/components/uiComponents/ui-component'
import Slot from '@/components/uiComponents/slot/index'

@Component({
  components: {
    ChildDropZone,
  },
})
export default class SlotBuilder extends UIComponent<Slot> {}
