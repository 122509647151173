




import { Component } from 'vue-property-decorator'

import { UIComponent } from '@/components/uiComponents/ui-component'
import Mf from '@/components/uiComponents/mf'
import mfRender from '@/components/uiComponents/mf/mfRender'

@Component({
  components: {
    mfRender,
  },
})
export default class UIMf extends UIComponent<Mf> {}
