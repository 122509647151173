

































import { Vue, Component } from 'vue-property-decorator'
import { SharedConstants } from '@knitiv/api-client-javascript'

import Rights from '@/components/knitiv/rights.vue'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import { InjectAPI } from '@/utils/api'
import { BaseNode } from '@/models/node/base-node'
import { Right } from '@/models/node/right'

@Component({
  components: {
    Rights,
    JSONEditor,
  },
  mixins: [InjectAPI],
})
export default class RightsDev extends Vue {
  rights1: Record<string, Right> | null = null

  isLoading = true

  async mounted() {
    const response = await this.$api.objectInfo({ kid: SharedConstants.nodes.PERSON })

    const node = BaseNode.fromObjectInfo(response)

    console.log('node.rights', node.rights)
    this.rights1 = node.rights
    this.isLoading = false
  }
}
