import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import {
  form,
  createNumber,
} from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'

export default class TextArea extends PageBuilderSyncComponent {
  loader = 'image'

  description = 'A simple input'

  icon = 'mdi-form-textbox'

  options = {
    ...form,
    rows: createNumber('Lignes', 5),
  }

  category = Category.INPUT

  name = 'Zone de texte'
}
