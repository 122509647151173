
import { Component, Vue } from 'vue-property-decorator'
import {
  VCard, VCardActions, VCardSubtitle, VCardText, VCardTitle,
} from 'vuetify/lib'
import { listChildItems } from '@/components/uiComponents/renderUtils'
import { component } from '@/models/architecture'

@Component({
  // @ts-ignore
  functional: true,
  // @ts-ignore
  render(createElement, { props }) {
    const item: component<any> = props.rootItem
    const components: any[] = []
    const rootComponents: any[] = [VCardTitle, VCardSubtitle, VCardText, VCardActions]
    for (let i = 0; i < item.components.length; i++) {
      if (item.components[i].components.length === 0) {
        continue
      }
      components.push(createElement(
        rootComponents[i],
        item.components[i].node,
        listChildItems(item.components[i], createElement),
      ))
    }
    return createElement(
      VCard,
      item.node,
      components,
    )
  },
})
export default class cardRender extends Vue {}
