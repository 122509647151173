

































import { Component, Vue } from 'vue-property-decorator'
import PhoneInput from '@/components/knitiv/phone.vue'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'
import { ComponentOptions } from '@/models/architecture'

@Component({
  components: {
    PhoneInput,
    JSONEditor,
    OptionsDisplayer,
  },
})
export default class PhoneInputDev extends Vue {
  options: ComponentOptions = {}

  phone = {
    country: '',
    value: '',
  }

  phone2 = {
    country: 'FR',
    value: '0642000000',
  }

  mounted() {
    this.options = getDefinition(ComponentType.PHONE_INPUT).options
    console.log('this.options', this.options)
  }
}
