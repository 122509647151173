








import { SharedConstants } from '@knitiv/api-client-javascript'
import { Vue, Component } from 'vue-property-decorator'
import MfInput from '@/components/mf/mf.vue'
import { InjectAPI } from '@/utils/api'

@Component({
  inheritAttrs: false,
  components: {
    MfInput,
  },
  mixins: [InjectAPI],
})
export default class Email extends Vue {
  email = SharedConstants.types.EMAIL
}
