




import { Component } from 'vue-property-decorator'
import Radio from '@/components/uiComponents/button'
import { UIComponent } from '@/components/uiComponents/ui-component'
import radioRender from '@/components/uiComponents/radio/radioRender'

@Component({
  components: {
    radioRender,
  },
})
export default class radioBuilder extends UIComponent<Radio> {}
