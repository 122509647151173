















import { Component, Inject } from 'vue-property-decorator'
import { VContainer } from 'vuetify/lib'
import { UIComponent } from '@/components/uiComponents/ui-component'
import Container from '@/components/uiComponents/container'
import ChildDropZone from '@/components/ChildDropZone.vue'

@Component({
  components: {
    ChildDropZone,
  },
})
export default class ContainerBuilder extends UIComponent<Container> {
  @Inject({
    default: {
      VContainer,
    },
  }) readonly injected!: any

  get style() {
    return {
      flexDirection: this.props.direction,
      margin: this.props.direction === 'row' ? '0 15px' : '15px 0',
    }
  }
}
