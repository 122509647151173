












import { Component } from 'vue-property-decorator'

import { UIComponent } from '@/components/uiComponents/ui-component'
import Panel from '@/components/uiComponents/panel'
import PanelVue from '@/components/knitiv/panel.vue'
import ChildDropZone from '@/components/ChildDropZone.vue'

@Component({
  components: {
    PanelVue,
    ChildDropZone,
  },
})
export default class UIPanel extends UIComponent<Panel> {}
