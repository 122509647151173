












import { Vue, Component } from 'vue-property-decorator'
import MfInput from '@/components/mf/mf.vue'
import MfTree from '@/components/mf/mftreeview.vue'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'
import { ComponentOptions } from '@/models/architecture'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'

@Component({
  components: {
    MfInput,
    MfTree,
    JSONEditor,
    OptionsDisplayer,
  },
})
export default class MfDev extends Vue {
  options: ComponentOptions = {}

  mounted() {
    this.options = getDefinition(ComponentType.TABS).options
    console.log('this.options', this.options)
  }
}
