import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { createString } from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'

export default class Text extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Texte'

  description = 'Du texte'

  icon = 'mdi-format-text'

  category = Category.LAYOUT

  options = {
    text: createString('Texte', 'texte'),
  }
}
