




import { Component } from 'vue-property-decorator'
import PhoneInput from '@/components/uiComponents/phone'
import { UIComponent } from '@/components/uiComponents/ui-component'
import phoneInputRender from '@/components/uiComponents/phone/phoneInputRender'

@Component({
  components: {
    phoneInputRender,
  },
})
export default class PhoneInputBuilder extends UIComponent<PhoneInput> {}
