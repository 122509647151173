import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { ComponentOptions } from '@/models/architecture'

export default class RichInput extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Saisie enrichie'

  description = 'Un simple bouton'

  icon = 'mdi-gesture-tap'

  category = Category.INPUT

  options: ComponentOptions = {}
}
