




import { Component } from 'vue-property-decorator'
import { UIComponent } from '@/components/uiComponents/ui-component'
import spacerRender from '@/components/uiComponents/spacer/spacerRender'
import Spacer from '@/components/uiComponents/spacer'

@Component({
  components: {
    spacerRender,
  },
})
export default class SpacerBuilder extends UIComponent<Spacer> {}
