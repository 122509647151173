import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { createSelect } from '@/utils/options/creator'

export default class JSON extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'JSON'

  icon = 'mdi-code-json'

  category = Category.INPUT

  options = {
    mode: createSelect<string>('Mode', 'tree', 'Le mode de visualisation du JSON', {
      options: [
        {
          text: 'Arborescence',
          value: 'tree',
        },
        {
          text: 'Visualisation',
          value: 'view',
        },
        {
          text: 'Formulaire',
          value: 'form',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Texte',
          value: 'text',
        },
        {
          text: 'Prévisualisation',
          value: 'preview',
        },
      ],
    }),
  }
}
