



















import { Component, Prop } from 'vue-property-decorator'
import { Call, Get, Sync } from 'vuex-pathify'
import { Drag, DragAwareMixin } from 'vue-easy-dnd'
import vClickOutside from 'v-click-outside'
import { ComponentType } from '@/models/data'
import { Grid, RemoveComponentPayload, SelectedItem } from '@/models/pages/pagebuilder'
import { component } from '@/models/architecture'
import Icon from '@/components/Icon.vue'
import { getDefinition } from '@/components/definitions'
import { mixins } from 'vue-class-component'

@Component({
  components: {
    Drag,
    Icon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  inheritAttrs: true,
})
export default class LayoutItem extends mixins(DragAwareMixin) {
  @Prop({ type: String, required: true })
  id!: string

  @Prop({ type: String, required: true })
  root!: string

  @Prop({ type: Boolean, required: false, default: false })
  readonly!: boolean

  @Get('pagebuilder/grid')
  readonly grid!: Grid

  @Sync('pagebuilder/selectedItem')
  selectedItem!: SelectedItem

  @Call('pagebuilder/removeComponent')
  removeComponent!: (event: RemoveComponentPayload) => void

  onCut(root: string, itemId: string) {
    // TODO, do not remove when parent === destination, e.g. itself
    this.removeComponent({
      from: root,
      id: itemId,
    })
  }

  get inDragDefinition() {
    return this.dragType ? getDefinition(this.dragType) : '<Inconnu>'
  }

  get items() {
    return this.$accessor.pagebuilder.items
  }

  get isSelected() {
    return this.component.id === this.selectedItem?.id
  }

  clickOutsideConfig = {
    handler: this.onClickOutside,
    middleware: (event: any) => {
      const editor = document.getElementById('editor')
      return event.path.includes(editor)
    },
  }

  setSelectedItem(id: string | undefined) {
    return this.$accessor.pagebuilder.setSelectedItem(id)
  }

  onClickOutside() {
    console.log('deselect any item')
    this.setSelectedItem(undefined)
  }

  get component(): component<any> {
    return this.$accessor.pagebuilder.items[this.id]
  }

  $refs!: {
    component: HTMLFormElement;
  }

  get typeWrapper() {
    if (this.component && Object.values(ComponentType).includes(this.component.type)) {
      return this.component.type
    }
    return ComponentType.ERROR
  }

  selectItem(/* event: MouseEvent */) {
    this.$accessor.pagebuilder.setSelectedItem(this.id)
  }
}
