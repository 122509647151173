export interface dataGetter {
  fillme: string;
}

export enum ComponentType {
  // regular
  CONTAINER = 'kcontainer',
  INPUT = 'kinput',
  BUTTON = 'kbutton',
  CARD = 'kcard',
  LIST = 'klist',
  MF = 'kmf',
  COLUMNS = 'kcolumns',
  COLUMN = 'kcolumn',
  IMAGE = 'kimage',
  TEXT = 'ktext',
  SPACER = 'kspacer',
  TABS = 'ktabs',
  JSON_EDITOR = 'kjson',
  CHECKBOX = 'kcheckbox',
  EMAIL = 'kemail',
  PHONE_INPUT = 'kphone_input',
  TEXTAREA = 'ktextarea',
  RICH_INPUT = 'krich_input',
  PANEL = 'kpanel',
  RADIO = 'kradio',

  GROUP_LINK = 'kgroup-link',
  FORM_LINK = 'kform-link',

  PAGE = 'kpage',
  GROUP = 'kgroup',
  FORM = 'kform',

  // special
  ERROR = 'kerror',
  SLOT = 'kslot',
}
