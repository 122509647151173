























































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { nanoid } from 'nanoid'
import { AutocompleteItem, SharedConstants } from '@knitiv/api-client-javascript'

import Mf from '@/components/mf/mf.vue'
import { InjectAPI } from '@/utils/api'
import { Representation } from '@/models/node/representation'

@Component({
  components: {
    Mf,
  },
  mixins: [InjectAPI],
})
export default class Repre extends Vue {
  @Prop({ type: Object, required: true })
  readonly value!: Record<string, Representation>

  @Prop({ type: Boolean, required: false, default: false })
  readonly loading!: boolean

  @Prop({ type: String, required: false, default: 'view' })
  readonly mode!: 'view' | 'edit'

  representation: AutocompleteItem | null = null

  text = ''

  type = ''

  isEditMode = true

  types = [
    { text: 'Chaîne', value: SharedConstants.types.STRING },
    { text: 'Texte', value: SharedConstants.types.TEXT },
    { text: 'JSON', value: SharedConstants.types.JSON },
    { text: 'HTML', value: SharedConstants.types.HTML },
    { text: 'Nombre entier', value: SharedConstants.types.INTEGER },
    { text: 'Nombre décimal', value: SharedConstants.types.DOUBLE },
    { text: 'Nombre décimal (précision 8)', value: SharedConstants.types.DOUBLE8 },
    { text: 'Date', value: SharedConstants.types.DATE },
  ]

  onSelect(item: AutocompleteItem) {
    this.representation = item
  }

  removeRepresentation(key: string) {
    const { value } = this

    this.$delete(value, key)

    this.$emit('input', value)
  }

  addRepresentation() {
    const { value } = this

    if (this.representation) {
      this.$set(value, nanoid(), {
        value: this.text,
        _mode_: 'NORMAL',
        lang: {
          isa: this.representation.isa,
          kid: this.representation.kid,
          name: this.representation.name,
        },
        type_kid: this.type,
      })

      this.text = ''
      this.type = SharedConstants.types.STRING
      this.representation = null

      this.$emit('input', value)
    }
  }

  mounted() {
    this.isEditMode = this.mode === 'edit'
  }
}
