




































import { Component, Inject } from 'vue-property-decorator'
import {
  VCardActions, VCardSubtitle, VCardText, VCardTitle,
} from 'vuetify/lib'
import ChildSlot from '@/components/ChildSlot.vue'
import Card from '@/components/uiComponents/card'
import { UIComponent } from '@/components/uiComponents/ui-component'

@Component({
  components: {
    ChildSlot,
  },
})
export default class CardBuilder extends UIComponent<Card> {
  @Inject({
    default: {
      VCardText,
      VCardActions,
      VCardTitle,
      VCardSubtitle,
    },
  }) readonly injected!: any
}
