import { getterTree } from 'typed-vuex'
import Vue from 'vue'
import { MutationTree } from 'vuex'

interface SetValueModel {
  componentId: string;
  propertyValue: string;
  propertyName: string;
}

export enum EPropertyType {
  PROPS,
  STYLE
}

export interface GetValueModel {
  type: EPropertyType;
  pageId: string;
  componentId: string;
  propertyName: string;
}

// interface PageState {
//   infos: any;
// }

// State object
export const state = (): any => ({
  infos: {},
})

// Getter functions
export const getters = getterTree(state, {
  infos: (state) => state.infos,
})

// Mutations
export const mutations: MutationTree<any> = {
  SET_PROP(state, { componentId, propertyName, propertyValue }: SetValueModel) {
    Vue.set(state.infos, `${componentId}_${propertyName}`, propertyValue)
  },
}

// Actions
export const actions = {}

export default {
  actions,
  getters,
  state,
  mutations,
  namespaced: true,
}
