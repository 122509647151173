import InputLoader from '@/components/uiComponents/input/inputLoader.vue'
import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import {
  createString, createBoolean, createSelect, form,
} from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'

export default class Input extends PageBuilderSyncComponent {
  loader = InputLoader

  description = 'A simple input'

  icon = 'mdi-form-textbox'

  options = {
    ...form,
    validation: createSelect<string>('Validation', 'alphanum', 'Le type de validation à appliquer sur le champs', {
      options: [
        {
          text: 'Lettres',
          value: 'alpha',
        },
        {
          text: 'Lettre et chiffres',
          value: 'alphanum',
        },
        {
          text: 'Chiffres',
          value: 'num',
        },
        {
          text: 'Personnalisé',
          value: 'custom',
        },
      ],
    }),
    regex: createString('Regex', ''),
    prefix: createString('Préfixe', ''),
    suffix: createString('Suffixe', ''),
    secret: createBoolean('Secret'),
  }

  category = Category.INPUT

  name = 'Boîte de texte'
}
