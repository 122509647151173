import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { createSelect } from '@/utils/options/creator'

export default class Grid extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'Colonnes'

  icon = 'mdi-view-column'

  category = Category.LAYOUT

  options = {
    align: createSelect<string>('Alignement', 'center', 'Spécifie l\'alignement vertical des colonnes', {
      options: [
        {
          value: 'start',
          text: 'Début',
        },
        {
          value: 'center',
          text: 'Centre',
        },
        {
          value: 'end',
          text: 'Fin',
        },
        {
          value: 'baseline',
          text: 'Baseline',
        },
        {
          value: 'stretch',
          text: 'Étiré',
        },
      ],
    }),
    justify: createSelect<string>('Justification', 'center', 'Spécifie l\'alignement horizontal des colonnes', {
      options: [
        {
          value: 'start',
          text: 'Début',
        },
        {
          value: 'center',
          text: 'Centre',
        },
        {
          value: 'end',
          text: 'Fin',
        },
        {
          value: 'space-between',
          text: 'Espacé',
        },
        {
          value: 'space-around',
          text: 'Espacé de manière égale',
        },
      ],
    }),
  }
}
