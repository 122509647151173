




import { Component } from 'vue-property-decorator'
import Button from '@/components/uiComponents/button'
import { UIComponent } from '@/components/uiComponents/ui-component'
import ButtonRender from '@/components/uiComponents/button/buttonRender'

@Component({
  components: {
    ButtonRender,
  },
})
export default class ButtonBuilder extends UIComponent<Button> {}
