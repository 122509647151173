









import { Component } from 'vue-property-decorator'
import Group from '@/components/uiComponents/group'
import { UIComponent } from '@/components/uiComponents/ui-component'
import ChildDropZone from '@/components/ChildDropZone.vue'

@Component({
  components: {
    ChildDropZone,
  },
})
export default class GroupBuilder extends UIComponent<Group> {}
