import { ComponentType } from '@/models/data'
import { component } from '@/models/architecture'

export enum UIComponentType {
  PAGE = 'K_NODE;UIComponentType_page',
  GROUP = 'K_NODE;UIComponentType_group',
  FORM = 'K_NODE;UIComponentType_form'
}

export enum Category {
  LAYOUT = 'layout',
  MEDIA = 'media',
  INPUT = 'input',
  DATABASE = 'database',
  OTHER = 'other'
}

export type CategoryItems = Record<Category, { text: string; icon: string }>

export type TreeViewItem = {
  id: string;
  name: string;
  children: TreeViewItem[];
}

export type TreeView = TreeViewItem[]

export interface SwapItemsPayload {
  event: {
    from: number;
    to: number;
  };
  id: string;
}

export interface AddComponentPayload {
  parent: string;
  id: string;
  index?: number;
}

export interface ScriptEvent {
  code: string;
  name: string;
  target: string;
}

export interface ScriptVariables {
  [index: string]: {
    style: string[];
    props: string[];
  };
}

export type ScriptEvents = Record<string, ScriptEvent>

export interface VariablePayload {
  target: string;
  type: 'props' | 'style';
  key: string;
}

export interface SetHoveredItemPayload {
  id: string;
  ref: HTMLElement;
}

export interface AddItemPayload {
  component: component<any>;
  parent?: string;
  index?: number;
  select: boolean;
}

export interface SetItemPropertyPayload {
  id: string;
  key: string;
  value: any;
}

export interface SetItemKeyPayload {
  id: string;
  value: any;
}

export interface RemoveComponentPayload {
  from: string;
  id: string;
}

export interface DragDataTransfer {
  type: ComponentType;
  id: string | null;
}

export interface SetComponentsPayload {
  components: string[];
  id: string;
}

export type Grid = [number, number]

export interface Position {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}

export type SelectedItem = component<any> | undefined
