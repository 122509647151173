

























import { Component, Vue } from 'vue-property-decorator'
import * as icons from '@mdi/js'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'
import Icon from '@/components/Icon.vue'

@Component({
  components: {
    JSONEditor,
    OptionsDisplayer,
    Icon,
  },
})
export default class IconsDev extends Vue {
  icons = {}

  mounted() {
    this.icons = icons
  }
}
