import Vue, {
  Component, CreateElement, FunctionalComponentOptions, VNodeData, VueConstructor,
} from 'vue'
import { StandardProperties } from 'csstype'
import { getRenderVueComponents } from '@/components/definitions/renderDefinition'
import { component } from '@/models/architecture'
import { EPropertyType } from '@/store-dynamic/page'

export function listChildItems(
  rootItem: component<any>,
  createElement: CreateElement,
): any {
  const componentsList = []
  const vueComponents = getRenderVueComponents()
  for (const item of rootItem.components) {
    componentsList.push(createElement(
      // @ts-ignore
      vueComponents[item.type],
      {
        props: {
          rootItem: item,
        },
      },
    ))
  }
  return componentsList
}

export function createRender(
  component: VueConstructor | string | FunctionalComponentOptions | Component,
  style: ((item: VNodeData) => StandardProperties) | null = null,
) {
  return Vue.extend({
    // @ts-ignore
    functional: true,
    render(createElement: any, { props, parent }: { props: any; parent: any }) {
      const childs = []
      if (props.rootItem.components.length > 0) {
        childs.push(...listChildItems(props.rootItem, createElement))
      }
      if (style) {
        props.rootItem.node.style = {
          ...props.rootItem.node.style,
          ...style(props.rootItem.node),
        }
      }
      if (props.rootItem.dynamicProperties && props.rootItem.dynamicProperties.length > 0) {
        for (const value of props.rootItem.dynamicProperties) {
          if (value.type === EPropertyType.PROPS) {
            props.rootItem.node.props[value.propertyName] = parent.$store.getters[`${value.pageId}/infos`][`${value.componentId}_${value.propertyName}`]
          } else if (value.type === EPropertyType.STYLE) {
            if (!props.rootItem.node.style) {
              props.rootItem.node.style = {}
            }
            props.rootItem.node.style[value.propertyName] = parent.$store.getters[`${value.pageId}/infos`][`${value.componentId}_${value.propertyName}`]
          }
        }
      }
      return createElement(
        component,
        props.rootItem.node,
        childs,
      )
    },
  })
}
