













import { Component } from 'vue-property-decorator'
import Text from '@/components/uiComponents/text'
import { UIComponent } from '@/components/uiComponents/ui-component'
import textRender from '@/components/uiComponents/text/textRender'

@Component({
  components: {
    textRender,
  },
})
export default class TextBuilder extends UIComponent<Text> {}
