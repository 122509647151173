import { Component } from 'vue-property-decorator'
import { Prop, Vue } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { getDefinition } from '../definitions'
import { PageBuilderComponent } from '../definitions/pageBuilderComponent'
import { component } from '@/models/architecture'
import { ComponentType } from '@/models/data'

@Component
export class UIComponent <T extends PageBuilderComponent> extends Vue {
  @Prop({
    required: false,
    type: String,
    default: '',
  })
  itemId!: string

  @Prop({
    required: false,
    type: String,
  })
  type!: ComponentType | undefined

  // errorCaptured (err: Error, vm: Vue, info: string) {
  //   return false
  // }

  get item(): component<T> {
    if (this.$store && this.itemId) {
      return this.$accessor.pagebuilder.items[this.itemId]
    } if (this.type) {
      return {
        id: '',
        name: '',
        type: this.type,
        components: [],
        data: {},
        node: {},
      }
    }
    // @ts-ignore
    return {}
  }

  get props() {
    return this.node.props ?? {}
  }

  get node() {
    return this.item?.node ?? {}
  }

  get rootItem() {
    return { rootItem: cloneDeep(this.item) }
  }

  get style(): string | any[] | any {
    return this.node.style ?? {}
  }

  get value(): any {
    return this.item?.data ?? undefined
  }

  set value(data: any) {
    this.$accessor.pagebuilder.SET_DATA({
      id: this.itemId,
      value: data,
    })
  }

  get components() {
    return this.item?.components ?? []
  }

  get isPageBuilder(): boolean {
    return this.$accessor.pagebuilder.isPageBuilder
  }

  get definition() {
    return getDefinition(this.item?.type)
  }

  setProperty(key: string, value: any) {
    this.$accessor.pagebuilder.setItemProperty({
      id: this.itemId,
      key,
      value,
    })
  }
}
