










import { Component, Inject } from 'vue-property-decorator'
import { VCol } from 'vuetify/lib'
import { UIComponent } from '@/components/uiComponents/ui-component'
import Column from '@/components/uiComponents/column'
import ChildDropZone from '@/components/ChildDropZone.vue'

@Component({
  components: {
    ChildDropZone,
  },
})
export default class ColumnBuilder extends UIComponent<Column> {
  @Inject({
    default: {
      VCol,
    },
  }) readonly injected!: any
}
