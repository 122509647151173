






import { Component } from 'vue-property-decorator'
import Input from '@/components/uiComponents/input'
import { UIComponent } from '@/components/uiComponents/ui-component'
import textareaRender from '@/components/uiComponents/textarea/textareaRender'

@Component({
  components: { textareaRender },
})
export default class InputBuilder extends UIComponent<Input> {}
