
import { Component, Vue } from 'vue-property-decorator'
import {
  VIcon, VTab, VTabItem, VTabs, VTabsSlider,
} from 'vuetify/lib'
import { listChildItems } from '@/components/uiComponents/renderUtils'

@Component({
  // @ts-ignore
  functional: true,
  // @ts-ignore
  render(createElement, { props }) {
    const item = props.rootItem
    const components: any[] = [
      createElement(
        VTabsSlider,
      ),
    ]
    let i = 0
    for (const tab of item.node.props.tabs) {
      components.push(
        createElement(
          VTab,
          {
            domProps: {
              key: i,
            },
          },
          [
            tab.name,
            createElement(
              VIcon,
              {},
              [
                tab.icon,
              ],
            ),
          ],
        ),
      )
      i++
    }
    i = 0
    for (const title of item.components) {
      let tabItems: any[] = []
      if (item.components.length > i) {
        tabItems = listChildItems(title, createElement)
      }
      components.push(
        createElement(
          VTabItem,
          {
            domProps: {
              key: i,
            },
          },
          tabItems,
        ),
      )
      i++
    }
    return createElement(
      VTabs,
      {
        props: {
          'icons-and-text': true,
        },
      },
      components,
    )
  },
})
export default class tabsRender extends Vue {}
