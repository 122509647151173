












import { Component } from 'vue-property-decorator'
import { Call } from 'vuex-pathify'
import { AutocompleteItem, UITree } from '@knitiv/api-client-javascript'

import ChildDropZone from '@/components/ChildDropZone.vue'
import { component } from '@/models/architecture'
import {
  AddComponentPayload,
  SetComponentsPayload,
} from '@/models/pages/pagebuilder'
import ChildSlot from '@/components/ChildSlot.vue'
import LayoutItem from '@/components/LayoutItem.vue'
import { UIComponent } from '@/components/uiComponents/ui-component'
import FormLink from '@/components/uiComponents/form-link'
import { InjectAPI } from '@/utils/api'

@Component({
  components: {
    LayoutItem,
    ChildDropZone,
    ChildSlot,
  },
  mixins: [
    InjectAPI,
  ],
})
export default class FormLinkBuilder extends UIComponent<FormLink> {
  get idToChild(): string | null {
    if (this.components.length >= 1) {
      return this.components[0]
    }
    return null
  }

  get link() {
    return this.props.link
  }

  @Call('pagebuilder/mergeItemsRaw')
  mergeItemsRaw!: (items: Record<string, component<any>>) => void

  @Call('pagebuilder/addComponent')
  addComponent!: (payload: AddComponentPayload) => void

  @Call('pagebuilder/setComponents')
  setComponents!: (payload: SetComponentsPayload) => void

  // @Watch('link')
  async onComponentChange(link: AutocompleteItem[]) {
    const existingComponents = this.components
    // empty if existing
    if (existingComponents.length > 0) {
      this.setComponents({
        components: [],
        id: this.itemId,
      })
    }

    const newKid = link[0].kid

    const response = await this.$api.objectInfo({
      kid: newKid,
    })
    const json = JSON.parse(response.kjson) as UITree

    this.mergeItemsRaw(json.items)
    this.$accessor.pagebuilder.SET_ACTIONS(json.actions)
    this.addComponent({
      parent: this.itemId,
      id: json.root,
      index: 0,
    })
  }
}
