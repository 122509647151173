import Vue, {
  CreateElement, VNode, VueConstructor, RenderContext,
} from 'vue'
import Loader from '@/components/display/loader/loader.vue'

export default (type: string, data: any): VueConstructor => {
  const defintion = {
    functional: true,
    render(h: CreateElement, ctx: RenderContext): VNode {
      const selfData = {
        props: {
          type,
          data,
        },
      }
      const mergedData = { ...ctx.data, ...selfData }
      console.log('mergedData', mergedData)
      return h(Loader, mergedData)
    },
  }

  return Vue.extend(defintion)
}
