import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'

export default class Slot extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Emplacement dynamique'

  display = false

  icon = 'mdi-select-place'

  options = {}
}
