




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Spacer extends Vue {
  @Prop({
    type: Number,
    required: false,
    default: 30,
  })
  height!: number
}
