


























































































































































































import { Component } from 'vue-property-decorator'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
import FormBase from '@/components/formBase.vue'

// TODO https://github.com/iliyaZelenko/tiptap-vuetify

@Component({
  components: {
    EditorContent,
    EditorMenuBar,
  },
})
export default class RichInput extends FormBase {
  // TODO correct type

  // @ts-ignore
  editor: Editor | null = null

  // @ts-ignore
  onUpdate(editor: Editor) {
    this.$emit('input', editor?.getHTML())
  }

  mounted() {
    this.editor = new Editor({
      content: `
          <h2>
            Hi there,
          </h2>
          <p>
            this is a very <em>basic</em> example .
          </p>
          <pre><code>body { display: none; }</code></pre>
          <ul>
            <li>
              A regular list
            </li>
            <li>
              With regular items
            </li>
          </ul>
          <blockquote>
            It's amazing 👏
            <br />
            – mom
          </blockquote>
        `,
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new HorizontalRule(),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
      ],
      onUpdate: this.onUpdate,
    })
  }

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
    }
  }
}
