import MfLoader from '@/components/uiComponents/mf/mfLoader.vue'
// import { KNODE } from '@knitiv/api-client-javascript'
// import { N_MAX_CARDINALITY } from '@/constants/app'
import { PageBuilderSyncComponent } from '@/components/definitions/pageBuilderComponent'
import {
  // createMF,
  createNumber,
  createSelect,
  // createString,
  form,
} from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'
import { ComponentOptions } from '@/models/architecture'

export const mfOptions: ComponentOptions = {
  cardFrom: createNumber('Entrées minimum', 0),
  cardTo: createNumber('Entrées maximum', 1),
  order: createSelect<string[]>('Ordre', ['K_NODE;FIRSTNAME'], 'La liste des classes suggérées par le MF', {
    options: [
      {
        // @ts-ignore
        value: ['K_NODE;FIRSTNAME'],
        text: 'Prénom',
      },
      {
        // @ts-ignore
        value: ['K_NODE;LASTNAME'],
        text: 'Nom',
      },
    ],
  }),
  // order: createMF('Ordre', [], {
  //   class: KNODE.SYS_CLASSE,
  //   cardFrom: 1,
  //   cardTo: N_MAX_CARDINALITY
  // }),
  ...form,
}

export default class Mf extends PageBuilderSyncComponent {
  loader = MfLoader

  icon = 'mdi-form-dropdown'

  options = mfOptions

  category = Category.INPUT

  name = 'Mf'
}
