














import { Vue, Component, Prop } from 'vue-property-decorator'
import { nanoid } from 'nanoid'
import KInput from '@/components/knitiv/kinput.vue'

@Component({
  components: {
    KInput,
  },
})
export default class Kid extends Vue {
  @Prop({ type: String })
  readonly value!: string

  @Prop({ type: String, default: 'K_NODE;' })
  readonly order!: string

  @Prop({ type: Boolean, default: false })
  readonly disableGeneration!: boolean

  onInput(newvalue: string) {
    this.$emit('input', newvalue)
  }

  generateKid() {
    this.$emit('input', nanoid())
  }
}
