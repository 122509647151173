import {
  PageBuilderComponents,
  PageBuilderRawDefinitions,
  PageBuilderVueComponents,
} from '@/components/definitions/pageBuilderComponent'
import { ComponentType } from '@/models/data'
import Page from '@/components/uiComponents/page'
import Card from '@/components/uiComponents/card'
import Image from '@/components/uiComponents/image'
import Input from '@/components/uiComponents/input'
import Button from '@/components/uiComponents/button'
import List from '@/components/uiComponents/list'
import ListBuilder from '@/components/uiComponents/list/listBuilder.vue'
import Mf from '@/components/uiComponents/mf'
import Container from '@/components/uiComponents/container'
import Columns from '@/components/uiComponents/columns'
import Column from '@/components/uiComponents/column'
import Text from '@/components/uiComponents/text'
import Spacer from '@/components/uiComponents/spacer'
import Tabs from '@/components/uiComponents/tabs'
import GroupLink from '@/components/uiComponents/group-link'
import FormLink from '@/components/uiComponents/form-link'
import Form from '@/components/uiComponents/form'
import Group from '@/components/uiComponents/group'
import DefaultError from '@/components/uiComponents/defaultError'
import Slot from '@/components/uiComponents/slot'
import JsonEditor from '@/components/uiComponents/json'
import Checkbox from '@/components/uiComponents/checkbox'
import Email from '@/components/uiComponents/email'
import PhoneInput from '@/components/uiComponents/phone'

import GroupLinkRender from '@/components/uiComponents/group-link/groupLinkRender'
import PageRender from '@/components/uiComponents/page/pageRender'
import CardRender from '@/components/uiComponents/card/cardRender.vue'
import ImageRender from '@/components/uiComponents/image/imageRender'
import InputRender from '@/components/uiComponents/input/inputRender'
import TextRender from '@/components/uiComponents/text/textRender'
import TabsRender from '@/components/uiComponents/tabs/tabsRender.vue'
import FormLinkRender from '@/components/uiComponents/form-link/formLinkRender.vue'
import ContainerRender from '@/components/uiComponents/container/containerRender'
import SpacerRender from '@/components/uiComponents/spacer/spacerRender'
import ColumnRender from '@/components/uiComponents/column/columnRender'
import FormRender from '@/components/uiComponents/form/formRender.vue'
import MfRender from '@/components/uiComponents/mf/mfRender'
import ColumnsRender from '@/components/uiComponents/columns/columnsRender'
import ErrorRender from '@/components/uiComponents/defaultError/errorRender.vue'
import GroupRender from '@/components/uiComponents/group/groupRender'
import SlotRender from '@/components/uiComponents/slot/slotRender.vue'
import JsonEditorRender from '@/components/uiComponents/json/jsonRender'
import CheckboxRender from '@/components/uiComponents/checkbox/checkboxRender'
import emailRender from '@/components/uiComponents/email/emailRender'
import phoneInputRender from '@/components/uiComponents/phone/phoneInputRender'
import TextArea from '@/components/uiComponents/textarea'
import textareaRender from '@/components/uiComponents/textarea/textareaRender'
import RichInput from '@/components/uiComponents/richInput'
import richInputRender from '@/components/uiComponents/richInput/richInputRender'
import panelRender from '@/components/uiComponents/panel/panelRender'
import Panel from '@/components/uiComponents/panel'
import Radio from '@/components/uiComponents/radio'
import radioRender from '@/components/uiComponents/radio/radioRender'
// import buttonRender from '@/components/uiComponents/button/buttonRender'

const rawRenderDefinitions: PageBuilderRawDefinitions = {
  [ComponentType.CARD]: new Card(CardRender),
  [ComponentType.INPUT]: new Input(InputRender),
  [ComponentType.LIST]: new List(ListBuilder),
  [ComponentType.MF]: new Mf(MfRender),
  [ComponentType.CONTAINER]: new Container(ContainerRender),
  [ComponentType.COLUMNS]: new Columns(ColumnsRender),
  [ComponentType.COLUMN]: new Column(ColumnRender),
  [ComponentType.IMAGE]: new Image(ImageRender),
  [ComponentType.TEXT]: new Text(TextRender),
  [ComponentType.SPACER]: new Spacer(SpacerRender),
  [ComponentType.TABS]: new Tabs(TabsRender),
  [ComponentType.JSON_EDITOR]: new JsonEditor(JsonEditorRender),
  [ComponentType.CHECKBOX]: new Checkbox(CheckboxRender),
  // @ts-ignore
  // [ComponentType.BUTTON]: new Button(buttonRender),
  [ComponentType.BUTTON]: new Button(() => import('@/components/uiComponents/button/buttonRender')),
  [ComponentType.EMAIL]: new Email(emailRender),
  [ComponentType.PHONE_INPUT]: new PhoneInput(phoneInputRender),
  [ComponentType.TEXTAREA]: new TextArea(textareaRender),
  [ComponentType.RICH_INPUT]: new RichInput(richInputRender),
  [ComponentType.PANEL]: new Panel(panelRender),
  [ComponentType.RADIO]: new Radio(radioRender),

  [ComponentType.GROUP_LINK]: new GroupLink(GroupLinkRender),
  [ComponentType.FORM_LINK]: new FormLink(FormLinkRender),

  [ComponentType.PAGE]: new Page(PageRender),
  [ComponentType.FORM]: new Form(FormRender),
  [ComponentType.GROUP]: new Group(GroupRender),
  [ComponentType.ERROR]: new DefaultError(ErrorRender),
  [ComponentType.SLOT]: new Slot(SlotRender),
}

const renderDefinitions = Object
  .values(ComponentType)
  // eslint-disable-next-line
  .reduce((acc, val) => {
    acc[val] = rawRenderDefinitions[val]
    return acc
  }, {} as PageBuilderComponents)

const definitionsRenderVue = {} as PageBuilderVueComponents
for (const el of Object.values(ComponentType)) {
  definitionsRenderVue[el] = renderDefinitions[el].getComponent()
}

export function getRenderVueComponents(): PageBuilderVueComponents {
  return definitionsRenderVue
}

export default renderDefinitions
