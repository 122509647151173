import { PageBuilderSyncComponent } from '@/components/definitions/pageBuilderComponent'
import {
  createBoolean,
  createString,
} from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'
import { ComponentOptions } from '@/models/architecture'

export const panelOptions: ComponentOptions = {
  hidden: createBoolean('Caché', false),
  disabled: createBoolean('Désactivé', false),
  collapsed: createBoolean('Fermé', false),
  collapsible: createBoolean('Refermable', true),

  tooltip: createString('Bulle d\'aide', ''),
  title: createString('Titre', 'Panel'),
}

export default class Panel extends PageBuilderSyncComponent {
  loader = 'image'

  icon = 'mdi-form-dropdown'

  options = panelOptions

  category = Category.OTHER

  name = 'Panel'
}
