








import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class KText extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  text!: string
}
