




import { Component } from 'vue-property-decorator'
import Button from '@/components/uiComponents/button'
import { UIComponent } from '@/components/uiComponents/ui-component'
import richInputRender from '@/components/uiComponents/richInput/richInputRender'

@Component({
  components: {
    richInputRender,
  },
})
export default class RichInputBuilder extends UIComponent<Button> {}
