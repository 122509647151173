




import { Component } from 'vue-property-decorator'

import { UIComponent } from '@/components/uiComponents/ui-component'
import Checkbox from '@/components/uiComponents/checkbox'
import CheckboxRender from '@/components/uiComponents/checkbox/checkboxRender'

@Component({
  components: {
    CheckboxRender,
  },
})
export default class CheckboxBuilder extends UIComponent<Checkbox> {}
