import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'

export default class Form extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'Formulaire'

  icon = ''

  display = false

  category = Category.INPUT

  options = {}
}
