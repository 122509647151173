





















import { Component, Vue } from 'vue-property-decorator'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'
import { ComponentOptions } from '@/models/architecture'
import RichInput from '@/components/knitiv/richInput.vue'

@Component({
  components: {
    JSONEditor,
    OptionsDisplayer,
    RichInput,
  },
})
export default class RichInputDev extends Vue {
  input = ''

  options: ComponentOptions = {}

  mounted() {
    this.options = getDefinition(ComponentType.RICH_INPUT).options
    console.log('this.options', this.options)
  }
}
