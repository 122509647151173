













import { Component, Prop, Vue } from 'vue-property-decorator'
import { component } from '@/models/architecture'
import ComponentRenderer from '@/components/display/componentRenderer.vue'

@Component({
  components: {
    ComponentRenderer,
  },
})
export default class Childs extends Vue {
  @Prop({ required: true }) readonly childs!: component<any>[]
}
