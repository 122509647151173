import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { createNumber } from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'

export default class Spacer extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Espace'

  icon = 'mdi-arrow-expand-vertical'

  category = Category.LAYOUT

  options = {
    height: createNumber('Hauteur', 50),
  }
}
