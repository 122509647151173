




import { Component } from 'vue-property-decorator'
import { UIComponent } from '@/components/uiComponents/ui-component'
import imageRender from '@/components/uiComponents/image/imageRender'
import Image from '@/components/uiComponents/image/index'

@Component({
  components: {
    imageRender,
  },
})
export default class ImageBuilder extends UIComponent<Image> {}
