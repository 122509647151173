








import { Vue, Component, Prop } from 'vue-property-decorator'
import { bone } from '@/models/architecture'

@Component
export default class Loader extends Vue {
  @Prop(Object)
  data!: bone

  @Prop({
    type: String,
    required: true,
  })
  type!: string

  style: any = {}

  mounted(): void {
    this.style = {
      ...this.data.size,
      ...this.data.position,
    }
  }
}
