













































































































































import { Vue, Component } from 'vue-property-decorator'
import MfInput from '@/components/mf/mf.vue'
import MfTree from '@/components/mf/mftreeview.vue'
import { N_MAX_CARDINALITY } from '@/constants/app'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'
import { ComponentOptions } from '@/models/architecture'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'

@Component({
  components: {
    MfInput,
    MfTree,
    JSONEditor,
    OptionsDisplayer,
  },
})
export default class MfDev extends Vue {
  get N() {
    return N_MAX_CARDINALITY
  }

  mf1 = []

  mf2 = []

  mf3 = []

  mf4 = []

  mf5 = [{
    kid: 'K_NODE;FN_FREDERIC',
    isa: 'K_NODE;FIRSTNAME',
    file: 0,
    selectable: 1,
    children: 1,
    type_kid: 'K_NODE;STRING',
    kjson: '',
    icon: 'wpforms',
    name: 'Frédéric',
    name_type_kid: 'K_NODE;STRING',
    lang: { kid: 'K_NODE;UNIVERSEL', isa: 'K_NODE;SYS_CLASSE' },
    repre_all: { 'K_NODE;UNIVERSEL': { name: 'Frédéric', lang_isa: 'K_NODE;SYS_CLASSE' } },
    isa_name: 'Prénom',
    isa_name_type_kid: 'K_NODE;STRING',
    isa_lang: { kid: 'K_NODE;FRANCAIS', isa: 'K_NODE;LANGUE' },
    child: [],
  }]

  mf6 = [{
    kid: 'K_NODE;FN_FREDERIC',
    isa: 'K_NODE;FIRSTNAME',
    file: 0,
    selectable: 1,
    children: 1,
    type_kid: 'K_NODE;STRING',
    kjson: '',
    icon: 'wpforms',
    name: 'Frédéric',
    name_type_kid: 'K_NODE;STRING',
    lang: { kid: 'K_NODE;UNIVERSEL', isa: 'K_NODE;SYS_CLASSE' },
    repre_all: { 'K_NODE;UNIVERSEL': { name: 'Frédéric', lang_isa: 'K_NODE;SYS_CLASSE' } },
    isa_name: 'Prénom',
    isa_name_type_kid: 'K_NODE;STRING',
    isa_lang: { kid: 'K_NODE;FRANCAIS', isa: 'K_NODE;LANGUE' },
    child: [],
  }, {
    kid: 'K_NODE;FN_QG',
    isa: 'K_NODE;FIRSTNAME',
    file: 0,
    selectable: 1,
    children: 1,
    type_kid: 'K_NODE;STRING',
    kjson: '',
    icon: 'wpforms',
    name: 'Quentin',
    name_type_kid: 'K_NODE;STRING',
    lang: { kid: 'K_NODE;UNIVERSEL', isa: 'K_NODE;SYS_CLASSE' },
    repre_all: { 'K_NODE;UNIVERSEL': { name: 'Quentin', lang_isa: 'K_NODE;SYS_CLASSE' } },
    isa_name: 'Prénom',
    isa_name_type_kid: 'K_NODE;STRING',
    isa_lang: { kid: 'K_NODE;FRANCAIS', isa: 'K_NODE;LANGUE' },
    child: [],
  }]

  mf7 = [{
    kid: 'K_NODE;FN_FREDERIC',
    // name: 'Frédéric'
  }]

  options: ComponentOptions = {}

  mounted() {
    this.options = getDefinition(ComponentType.MF).options
    console.log('this.options', this.options)
  }
}
