








import { Vue, Component } from 'vue-property-decorator'
import Loader from '@/components/display/loader/loader.vue'

@Component({
  components: {
    Loader,
  },
})
export default class ListLoader extends Vue {
  private data: any = {}
}
