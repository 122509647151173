
import { Component, Vue } from 'vue-property-decorator'

@Component({
  // @ts-ignore
  functional: true,
  // @ts-ignore
  render(createElement) {
    return createElement(
      'div',
      {
        class: 'kform',
      },
    )
  },
})
export default class formRender extends Vue {}
