

















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Radio extends Vue {
  @Prop({ type: Array, default: () => [] })
  items!: any[]

  @Prop({ type: String, default: '' })
  label!: string
}
