




import Vue from 'vue'
import { KNodeStack } from '@/pages/admin/models/knode-stack'
// import { ClassDesignerStack } from '@/models/node/node-builder'
import { InjectAPI } from '@/utils/api'

export default Vue.extend({
  mixins: [InjectAPI],
  async mounted(): Promise<void> {
    console.log('-----')

    const node = new KNodeStack()

    await node.commit('SET_KID', '1')

    console.log(JSON.stringify(node.export(), null, 2))
    // console.log('node', JSON.stringify(node.store.state.node))

    await node.undo()

    console.log(JSON.stringify(node.export(), null, 2))
    // console.log('node', JSON.stringify(node.store.state.node))

    await node.redo()

    console.log(JSON.stringify(node.export(), null, 2))

    await node.commit('SET_KID', '2')

    console.log(JSON.stringify(node.export(), null, 2))

    await node.commit('SET_KID', '3')

    console.log(JSON.stringify(node.export(), null, 2))

    await node.undo()

    console.log(JSON.stringify(node.export(), null, 2))

    await node.undo()

    console.log(JSON.stringify(node.export(), null, 2))

    await node.undo()

    console.log(JSON.stringify(node.export(), null, 2))

    // const node = new KNode('K_NODE;PERSON')

    // const classDesigner = new ClassDesignerStack()

    // classDesigner.addNode(node)
    // classDesigner.setName(node.id, 'AAA')
    // classDesigner.addRepresentation(node.id, {
    //   name: 'Mon noeud',
    //   kid: 'K_NODE;FRANCAIS'
    // })
    // classDesigner.addRepresentation(node.id, {
    //   name: 'My Node',
    //   kid: 'K_NODE;ANGLAIS'
    // })
    // classDesigner.undo()
    // classDesigner.redo()
    // classDesigner.removeRepresentation(node.id, 'K_NODE;ANGLAIS')
    // classDesigner.undo()
    // // classDesigner.removeRepresentation(node.id, 'K_NODE;ANGLAIS')

    // const exportData = classDesigner.export()
    // console.log(JSON.stringify(exportData))
    // console.log(JSON.stringify(classDesigner.store.state))

    // const classDesigner2 = new ClassDesignerStack()
    // classDesigner2.import(exportData)

    // console.log(JSON.stringify(classDesigner2.export()))
    // console.log(JSON.stringify(classDesigner2.store.state))

    // console.log('-----')
  },
})
