




import { Component } from 'vue-property-decorator'
import Form from '@/components/uiComponents/group'
import { UIComponent } from '@/components/uiComponents/ui-component'

@Component
export default class FormBuilder extends UIComponent<Form> {}
