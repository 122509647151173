import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { createBoolean, createSelect } from '@/utils/options/creator'

export default class Container extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Conteneur'

  icon = 'mdi-group'

  category = Category.LAYOUT

  options = {
    direction: createSelect('Direction', 'column', 'Spécifie la direction dans laquelle sera orientée le conteneur', {
      options: [
        {
          // @ts-ignore
          value: 'row',
          text: 'Verticale',
        },
        {
          text: 'Horizontale',
          // @ts-ignore
          value: 'column',
        },
      ],
    }),
    fluid: createBoolean('Prends tout l\'espace', true),
  }
}

// const config: Button = new Button()
//
// export default config
