


















































import { Vue, Component, Prop } from 'vue-property-decorator'
import countries from 'country-codes-list'
import KInput from '@/components/knitiv/kinput.vue'

// TODO https://github.com/ruimarinho/google-libphonenumber

@Component({
  inheritAttrs: false,
  components: {
    KInput,
  },
})
export default class PhoneInput extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  value!: any

  @Prop({ type: String, required: false, default: 'auto' }) mode!: 'auto' |'custom'

  @Prop({ type: String, required: false, default: '' }) code!: string

  states: any[] = [
  ]

  search = ''

  inputValue = this.value.value

  countryValue = this.value.country

  get filteredCountries() {
    if (this.search) {
      return this.states.filter((state) => state.text.toLowerCase().includes(this.search.toLowerCase()))
    }
    return this.states
  }

  created() {
    console.time('now')
    const states = countries.customList('countryCode', '{countryNameLocal}: +{countryCallingCode}')
    const formattedStates = []
    for (const state in states) {
      formattedStates.push({
        text: states[state],
        value: state,
      })
    }
    this.states = formattedStates

    if (!this.countryValue) {
      if (this.mode === 'auto') {
        // TODO get country code or via current lang
      } else if (this.mode === 'custom') {
        this.countryValue = this.code
      }
    }
    console.timeEnd('now')
  }
}
