



















import { Component, Prop, Vue } from 'vue-property-decorator'
import { Call } from 'vuex-pathify'
import { VueConstructor } from 'vue/types/vue'
import ChildDropZone from '@/components/ChildDropZone.vue'
import { AddItemPayload } from '@/models/pages/pagebuilder'
import { ComponentType } from '@/models/data'
import { component } from '@/models/architecture'
import { generateNewId } from '@/utils/pagebuilder'

@Component({
  components: {
    ChildDropZone,
  },
})
export default class ChildSlot extends Vue {
  @Prop({ type: String, required: true })
  root!: string

  @Prop({ required: false, default: 'div' })
  tag!: string | VueConstructor<Vue>

  @Prop({
    type: Number,
    required: false,
    default: -1,
  })
  index!: number

  @Prop({ type: String, required: true })
  name!: string

  @Prop({ type: String, required: false })
  description!: string | undefined

  @Call('pagebuilder/addItem')
  addItem!: (event: AddItemPayload) => void

  id = generateNewId(ComponentType.SLOT)

  get components() {
    return this.item?.components
  }

  get isPageBuilder() {
    return this.$accessor.pagebuilder.isPageBuilder
  }

  get slotComponents(): string[] {
    if (this.root) {
      return this.$accessor.pagebuilder.items[this.root].components ?? []
    }
    return []
  }

  get item(): component<any> | undefined {
    const resolvedSlot = this.slotComponents[this.index]
    return this.$accessor.pagebuilder.items[resolvedSlot]
  }

  mounted() {
    const foundComponent = this.slotComponents[this.index]
    if (foundComponent) {
      this.id = this.slotComponents[this.index]
    } else {
      console.log('component not found')
      this.addItem({
        index: undefined,
        component: {
          id: this.id,
          name: this.id,
          data: undefined,
          components: [],
          node: {},
          type: ComponentType.SLOT,
        },
        parent: this.root,
        select: false,
      })
    }
  }
}
