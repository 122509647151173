import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { mfOptions } from '@/components/uiComponents/mf'
import { Category } from '@/models/pages/pagebuilder'
import { ComponentOptions } from '@/models/architecture'

const {
  order, // discard
  ...emailOptions
} = mfOptions

export default class Email extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Saisie d\'email'

  description = 'Un simple bouton'

  icon = 'mdi-gesture-tap'

  category = Category.INPUT

  options = emailOptions as ComponentOptions
}
