import {
  InputNumberOptions, ItemListOptions,
  MfOption,
  MfOptionSingleKid,
  Option,
  SelectOptions,
  TabsOptions,
} from '@/models/options'
import { OptionTypes } from '@/models/architecture'

export function createBoolean(label = 'Booléen', value = false, description = ''): Option<boolean> {
  return () => ({
    label,
    type: OptionTypes.CHECKBOX,
    default: value,
    description,
  })
}

export function createString(label = 'Chaîne', value = '', description = ''): Option<string> {
  return () => ({
    label,
    type: OptionTypes.INPUT_STRING,
    default: value,
    description,
  })
}

export function createNumber(
  label = 'Nombre',
  value = 0,
  description = '',
  options?: InputNumberOptions,
): Option<number> {
  return () => ({
    label,
    type: OptionTypes.INPUT_NUMBER,
    default: value,
    description,
    ...options,
  })
}

export function createColor(label = 'Couleur', value = '#fff', description = ''): Option<string> {
  return () => ({
    label,
    type: OptionTypes.COLOR_PICKER,
    default: value,
    description,
  })
}

export function createSelect<T = string>(label = 'Sélection', value: T, description = '', options: SelectOptions<T>): Option<T> {
  return () => ({
    label,
    type: OptionTypes.SELECT,
    default: value,
    description,
    ...options,
  })
}

export function createTabs(label = 'Onglets', value: any[], description = '', options: TabsOptions): Option<any[]> {
  return () => ({
    label,
    type: OptionTypes.TABS,
    default: value,
    description,
    ...options,
  })
}

export function createItemList(label = 'Elements', value: any[], description = '', options: ItemListOptions): Option<any[]> {
  return () => ({
    label,
    type: OptionTypes.ITEM_LIST,
    default: value,
    description,
    ...options,
  })
}

export function createMF(label = 'MF', value: any[], description = '', options: MfOption): Option<any[]> {
  return () => ({
    label,
    type: OptionTypes.MF,
    default: value,
    description,
    ...options,
  })
}

export function createMFSingleKid(label = 'MF', value: string, description = '', options: MfOptionSingleKid): Option<string> {
  return () => ({
    label,
    type: OptionTypes.MF_SINGLE_KID,
    default: value,
    description,
    ...options,
  })
}

// submit: submit a false: ne pas submit. affiche uniquement dans un form
// hidden: caché ou montré
// required: étoile requis
// disabled: désactivé
export const form = {
  label: createString('Label', 'Label'),
  tooltip: createString('Info-bulle', ''),
  placeholder: createString('Placeholder', 'Placeholder'),
  hideLabel: createBoolean('Cacher le label'),
  disabled: createBoolean('Désactivé'),
  hidden: createBoolean('Caché'),
  required: createBoolean('Requis'),
  submit: createBoolean('Publié'),
}
