











































import { Component, Vue } from 'vue-property-decorator'
import KInput from '@/components/knitiv/kinput.vue'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'
import { ComponentOptions } from '@/models/architecture'

@Component({
  components: {
    KInput,
    JSONEditor,
    OptionsDisplayer,
  },
})
export default class InputDev extends Vue {
  input = ''

  options: ComponentOptions = {}

  mounted() {
    this.options = getDefinition(ComponentType.INPUT).options
    console.log('this.options', this.options)
  }
}
