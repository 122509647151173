






























import { Component } from 'vue-property-decorator'
import Tabs from '@/components/uiComponents/tabs'
import { UIComponent } from '@/components/uiComponents/ui-component'
import ChildSlot from '@/components/ChildSlot.vue'

@Component({
  components: {
    ChildSlot,
  },
})
export default class TabsBuilder extends UIComponent<Tabs> {
  tab = 0

  get tabs() {
    return this.props.tabs
  }
}
