import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'

// const asyncModuleImport = import('~/components/page-builder/defaultError/component.vue')

export default class DefaultError extends PageBuilderSyncComponent {
  display = false

  icon = ''

  name = 'Error'

  options = {}
}
