import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { createTabs } from '@/utils/options/creator'

export default class Tabs extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Onglets'

  description = 'Onglets'

  category = Category.LAYOUT

  icon = 'mdi-tab'

  options = {
    tabs: createTabs('Onglets', [
      {
        name: 'Onglet 1', icon: 'mdi-plus', disabled: false, hidden: false,
      },
      {
        name: 'Onglet 2', icon: 'mdi-minus', disabled: false, hidden: false,
      },
    ], 'L\'ensemble des onglets utilisés par la page', {}),
  }
}
