
import { Vue } from 'vue-property-decorator'
import JSONEditor from '@/components/knitiv/json-editor.vue'

export default Vue.extend({
  // @ts-ignore
  functional: true,
  render(createElement: any, { props }: { props: any }) {
    console.log('props', props)
    return createElement(
      JSONEditor,
      props.rootItem.node,
    )
  },
})
