























import { Component } from 'vue-property-decorator'

import Childs from '@/components/display/childs.vue'
import { UIComponent } from '@/components/uiComponents/ui-component'
import List from '@/components/uiComponents/list'

@Component({
  components: {
    Childs,
  },
})
export default class ListBuilder extends UIComponent<List> {
  items: string[] = [
    'item1',
    'item2',
    'item3',
  ]

  title = 'Workspaces'

  onClick(item: string): void {
    console.log('clicked item', item)
  }
}
