






















import { Component } from 'vue-property-decorator'
import { UIComponent } from '@/components/uiComponents/ui-component'
import ComponentError from '@/components/uiComponents/defaultError'

@Component
export default class ErrorBuilder extends UIComponent<ComponentError> {}
