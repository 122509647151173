import { V4Constants } from '@knitiv/api-client-javascript'
import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { createMFSingleKid } from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'

export default class FormLink extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'Formulaire'

  icon = 'mdi-link-box-variant'

  category = Category.OTHER

  options = {
    link: createMFSingleKid('Composant', '', 'Définis sur quel élément pointera le formulaire', {
      cardFrom: 1,
      cardTo: 1,
      class: V4Constants.nodes.UI_COMPONENT,
      filterOn: V4Constants.nodes.UI_COMPONENT_TYPE_FORM,
    }),
  }
}
