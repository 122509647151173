




































import { Vue, Component } from 'vue-property-decorator'
import Page from '@/components/layouts/page.vue'

@Component({
  components: {
    Page,
  },
})
export default class Dev extends Vue {
  pages: string[] = [
    // 'input',
    // 'json-editor',
    // 'kid',
    // 'mf',
    // 'repre',
    // 'rights'
  ]

  drawer = true

  created() {
    // @ts-ignore
    // eslint-disable-next-line
    const ctx = require.context('./dev', true, /\.vue$/)
    const cache = {}
    const importAll = (requireContext: any) => requireContext.keys().forEach((key: string) => {
      // @ts-ignore
      cache[key] = requireContext(key)
    })

    importAll(ctx)

    // finally, we can loop over our cache's keys and add the HTML to our output element
    Object.keys(cache).forEach((key: string) => {
      if (key !== './index.vue') {
        // @ts-ignore
        const match: string | undefined = key.match(/.\/(.*)\.vue/)?.[1]
        if (match) {
          this.pages.push(match)
        }
      }
    })
  }
}
