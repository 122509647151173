































import { Vue, Component } from 'vue-property-decorator'
import { SharedConstants } from '@knitiv/api-client-javascript'
import Repre from '@/components/knitiv/repre.vue'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import { InjectAPI } from '@/utils/api'
import { BaseNode } from '@/models/node/base-node'

@Component({
  components: {
    Repre,
    JSONEditor,
  },
  mixins: [InjectAPI],
})
export default class RepreDev extends Vue {
  repre1 = {}

  isLoading = true

  test() {
    // this.$toast("I'm a toast")
  }

  async mounted() {
    const response = await this.$api.objectInfo({ kid: SharedConstants.nodes.PERSON })

    const node = BaseNode.fromObjectInfo(response)

    console.log('node.representations', node.representations)
    this.repre1 = node.representations
    this.isLoading = false
  }
}
