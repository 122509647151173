








import { Vue, Component } from 'vue-property-decorator'
import Loader from '@/components/display/loader/loader.vue'

@Component({
  components: {
    Loader,
  },
})
export default class InputLoader extends Vue {
  private data: Record<string, any> = {
    height: '66px',
  }
}
