import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import {
  form,
  createItemList,
} from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'
import { ComponentOptions } from '@/models/architecture'

const { placeholder, ...radioOptions } = form

export default class Radio extends PageBuilderSyncComponent {
  loader = 'button'

  name = 'Radio'

  description = 'Un simple bouton'

  icon = 'mdi-gesture-tap'

  category = Category.OTHER

  options: ComponentOptions = {
    items: createItemList('Elements', [{
      name: 'Radio 1',
      value: 'radio1',
    }, {
      name: 'Radio 2',
      value: 'radio2',
    }], 'La liste des valeurs possibles', {}),
    ...radioOptions,
  }
}
