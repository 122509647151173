import Button from '@/components/uiComponents/button'
import Card from '@/components/uiComponents/card'
import List from '@/components/uiComponents/list'
import Mf from '@/components/uiComponents/mf'
import Columns from '@/components/uiComponents/columns'
import Column from '@/components/uiComponents/column'
import Container from '@/components/uiComponents/container'
import Slot from '@/components/uiComponents/slot'
import Page from '@/components/uiComponents/page'
import Form from '@/components/uiComponents/form'
import Group from '@/components/uiComponents/group'
import Image from '@/components/uiComponents/image'
import Text from '@/components/uiComponents/text'
import GroupLink from '@/components/uiComponents/group-link'
import FormLink from '@/components/uiComponents/form-link'
import Spacer from '@/components/uiComponents/spacer'
import Tabs from '@/components/uiComponents/tabs'
import Input from '@/components/uiComponents/input'
import JsonEditor from '@/components/uiComponents/json'
import Checkbox from '@/components/uiComponents/checkbox'
import Email from '@/components/uiComponents/email'
import PhoneInput from '@/components/uiComponents/phone'
import Textarea from '@/components/uiComponents/textarea'

import { ComponentType } from '@/models/data'
import {
  PageBuilderComponent,
  PageBuilderComponents,
  PageBuilderRawDefinitions,
  PageBuilderVueComponents,
} from '@/components/definitions/pageBuilderComponent'
import ButtonBuilder from '@/components/uiComponents/button/buttonBuilder.vue'
import CardBuilder from '@/components/uiComponents/card/cardBuilder.vue'
import InputBuilder from '@/components/uiComponents/input/inputBuilder.vue'
import MfBuilder from '@/components/uiComponents/mf/mfBuilder.vue'
import ListBuilder from '@/components/uiComponents/list/listBuilder.vue'
import ContainerBuilder from '@/components/uiComponents/container/containerBuilder.vue'
import ColumnsBuilder from '@/components/uiComponents/columns/columnsBuilder.vue'
import ColumnBuilder from '@/components/uiComponents/column/columnBuilder.vue'
import ImageBuilder from '@/components/uiComponents/image/imageBuilder.vue'
import TextBuilder from '@/components/uiComponents/text/textBuilder.vue'
import SpacerBuilder from '@/components/uiComponents/spacer/spacerBuilder.vue'
import TabsBuilder from '@/components/uiComponents/tabs/tabsBuilder.vue'
import JsonEditorBuilder from '@/components/uiComponents/json/jsonBuilder.vue'
import CheckboxBuilder from '@/components/uiComponents/checkbox/checkboxBuilder.vue'
import GroupLinkBuilder from '@/components/uiComponents/group-link/groupLinkBuilder.vue'
import FormLinkBuilder from '@/components/uiComponents/form-link/formLinkBuilder.vue'
import PageBuilder from '@/components/uiComponents/page/pageBuilder.vue'
import FormBuilder from '@/components/uiComponents/form/formBuilder.vue'
import GroupBuilder from '@/components/uiComponents/group/groupBuilder.vue'
import ErrorBuilder from '@/components/uiComponents/defaultError/errorBuilder.vue'
import SlotBuilder from '@/components/uiComponents/slot/slotBuilder.vue'
import DefaultError from '@/components/uiComponents/defaultError'
import EmailBuilder from '@/components/uiComponents/email/emailBuilder.vue'
import PhoneInputBuilder from '@/components/uiComponents/phone/phoneInputlBuilder.vue'
import TextareaBuilder from '@/components/uiComponents/textarea/textAreaBuilder.vue'
import RichInput from '@/components/uiComponents/richInput'
import RichInputBuilder from '@/components/uiComponents/richInput/richInputBuilder.vue'
import Panel from '@/components/uiComponents/panel'
import panelBuilder from '@/components/uiComponents/panel/panelBuilder.vue'
import Radio from '@/components/uiComponents/radio'
import radioBuilder from '@/components/uiComponents/radio/radioBuilder.vue'

const rawDefinitions: PageBuilderRawDefinitions = {
  [ComponentType.BUTTON]: new Button(ButtonBuilder),
  [ComponentType.CARD]: new Card(CardBuilder),
  [ComponentType.INPUT]: new Input(InputBuilder),
  [ComponentType.LIST]: new List(ListBuilder),
  [ComponentType.MF]: new Mf(MfBuilder),
  [ComponentType.CONTAINER]: new Container(ContainerBuilder),
  [ComponentType.COLUMNS]: new Columns(ColumnsBuilder),
  [ComponentType.COLUMN]: new Column(ColumnBuilder),
  [ComponentType.IMAGE]: new Image(ImageBuilder),
  [ComponentType.TEXT]: new Text(TextBuilder),
  [ComponentType.SPACER]: new Spacer(SpacerBuilder),
  [ComponentType.TABS]: new Tabs(TabsBuilder),
  [ComponentType.JSON_EDITOR]: new JsonEditor(JsonEditorBuilder),
  [ComponentType.CHECKBOX]: new Checkbox(CheckboxBuilder),
  [ComponentType.EMAIL]: new Email(EmailBuilder),
  [ComponentType.PHONE_INPUT]: new PhoneInput(PhoneInputBuilder),
  [ComponentType.TEXTAREA]: new Textarea(TextareaBuilder),
  [ComponentType.RICH_INPUT]: new RichInput(RichInputBuilder),
  [ComponentType.PANEL]: new Panel(panelBuilder),
  [ComponentType.RADIO]: new Radio(radioBuilder),

  [ComponentType.GROUP_LINK]: new GroupLink(GroupLinkBuilder),
  [ComponentType.FORM_LINK]: new FormLink(FormLinkBuilder),

  [ComponentType.PAGE]: new Page(PageBuilder),
  [ComponentType.FORM]: new Form(FormBuilder),
  [ComponentType.GROUP]: new Group(GroupBuilder),

  [ComponentType.ERROR]: new DefaultError(ErrorBuilder),
  [ComponentType.SLOT]: new Slot(SlotBuilder),
}

const definitions = Object
  .values(ComponentType)
  // eslint-disable-next-line
  .reduce((acc, val) => {
    acc[val] = rawDefinitions[val]
    return acc
  }, {} as PageBuilderComponents)

const definitionsVue = {} as PageBuilderVueComponents
for (const el of Object.values(ComponentType)) {
  definitionsVue[el] = definitions[el].getComponent()
}

export function getDefinition(
  type: ComponentType,
): PageBuilderComponent {
  if (type in definitions) {
    return definitions[type]
  }
  console.warn(`No definition found for ${type}\nYou must register the component in the definition file (this one)`)
  return definitions[ComponentType.ERROR]
}

export function getDefinitions(): PageBuilderComponents {
  return definitions
}

export function getRawDefinitions(): PageBuilderRawDefinitions {
  return rawDefinitions
}

export function getVueComponents(): PageBuilderVueComponents {
  return definitionsVue
}

export default definitions
