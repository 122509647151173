import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'

export default class Group extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'Groupe'

  icon = ''

  category = Category.OTHER

  display = false

  options = {}
}
