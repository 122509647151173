import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { createBoolean, createNumber, createString } from '@/utils/options/creator'
import { Category } from '@/models/pages/pagebuilder'

export default class Image extends PageBuilderSyncComponent {
  loader = 'image'

  options = {
    src: createString('Source', 'http://placehold.jp/100x50.png?text=Aucune%20image%20d%C3%A9finie'),
    hidden: createBoolean('Cachée', false),
    contain: createBoolean('Contenue', true),
    height: createNumber('Hauteur', 100),
    caption: createString('Légende', ''),
    hideCaption: createBoolean('Cacher la légende', false),
  }

  icon = 'mdi-image-size-select-actual'

  category = Category.MEDIA

  name = 'Image'
}
