











































































import {
  Component, Inject, Prop
} from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import {
  Drag, DragAwareMixin, Drop, DropList, InsertEvent, ReorderEvent,
} from 'vue-easy-dnd'
import { Call, Get, Sync } from 'vuex-pathify'
import LayoutItem from '@/components/LayoutItem.vue'
import {
  AddItemPayload,
  DragDataTransfer,
  RemoveComponentPayload,
  SwapItemsPayload,
} from '@/models/pages/pagebuilder'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'
import { component, Properties } from '@/models/architecture'
import { generateNewId } from '@/utils/pagebuilder'

function UIComponentFactory(
  componentType: ComponentType,
  componentId: string,
): component<any> {
  const component: component<any> = {
    components: [],
    node: {},
    type: componentType,
    data: undefined,
    id: componentId,
    name: componentId,
  }
  const definition = getDefinition(componentType)
  let properties = {}

  console.log('-------------------------')

  if (definition?.options) {
    const { options } = definition
    // eslint-disable-next-line
    properties = Object.entries(options).reduce(
      (acc: Properties<any>, [key, option]) => {
        const resolvedOption = option(component)
        // @ts-ignore
        acc[key] = resolvedOption.default
        return acc
      },
      {},
    )
  }

  component.node.props = properties

  return component
}

@Component({
  components: {
    Drag,
    Drop,
    DropList,
    LayoutItem,
  },
  inheritAttrs: false,
})
export default class ChildDropZone extends mixins(DragAwareMixin) {
  @Prop({ type: String, required: true })
  root!: string

  @Prop({ required: false, default: 'div' })
  tag!: string

  @Prop({ type: String, required: false, default: 'row' })
  direction!: 'row' | 'column'

  @Prop({ type: Array, required: false, default: () => [] })
  accept!: string[]

  @Sync('pagebuilder/items@:root')
  rootItem!: component<any>

  @Get('pagebuilder/items')
  items!: Record<string, component<any>>

  hover = false

  @Inject({
    default: {
      LayoutItem,
    },
  }) readonly injectComponents!: any

  mounted() {
    //
  }

  get isPanning() {
    return this.$accessor.pagebuilder.isPanning
  }

  get style() {
    return {
      flexDirection: this.direction,
    }
    // return this.root === this.globalRootId ? { height: '100%' } : { flex: 1 }
  }

  get components(): string[] {
    return this.$accessor.pagebuilder.items[this.root]?.components
  }

  get resolvedComponents(): string[] {
    const { items } = this.$accessor.pagebuilder
    // @ts-ignore
    return items[this.root]?.components.map((id: string) => items[id])
  }

  get inDragDefinition() {
    return this.dragType ? getDefinition(this.dragType) : '<Inconnu>'
  }

  //
  // set components (item: Item) {
  //   //
  // }

  @Call('pagebuilder/swapItems')
  swapItems!: (event: SwapItemsPayload) => void

  @Call('pagebuilder/addItem')
  addItem!: (event: AddItemPayload) => void

  @Call('pagebuilder/removeComponent')
  removeComponent!: (event: RemoveComponentPayload) => void

  @Call('pagebuilder/setSelectedItem')
  setSelectedItem!: (id: string | undefined) => void

  get globalRootId() {
    return this.$store.state.pagebuilder.rootId
  }

  onDragStart(/* event: DragEvent */) {
    //
  }

  onDragEnd(/* event: DragEvent */) {
    //
  }

  private isChildOrSame(destination: string, source: string): boolean {
    const sourceItem = this.items[source]

    // both items are the same
    if (source === destination) {
      return true
    }

    // check every child
    for (let i = 0; i < sourceItem.components.length; i++) {
      // child id is the same as destination ?
      if (destination === sourceItem.components[i]) {
        return true
      }

      // otherwise, analyze the node
      const isChild = this.isChildOrSame(destination, sourceItem.components[i])

      if (isChild) {
        return true
      }
    }
    return false
  }

  filterAccept(item: DragDataTransfer) {
    const destId = this.root
    const destType = this.items[this.root].type
    const source = item.id
    const { type } = item

    // order is important!

    if (type === ComponentType.COLUMN && destType !== ComponentType.COLUMNS) {
      return false
    }

    // check if drop is possible
    if (source && this.isChildOrSame(destId, source)) {
      return false
    }

    // check what it accept
    if (this.accept.length === 0) {
      return true
    }
    return this.accept.includes(type)
  }

  onReorder(event: ReorderEvent) {
    this.swapItems({
      id: this.root,
      event: {
        from: event.from,
        to: event.to,
      },
    })
    this.setSelectedItem(undefined)
  }

  onCut(root: string, itemId: string) {
    // TODO, do not remove when parent === destination, e.g. itself
    this.removeComponent({
      from: root,
      id: itemId,
    })
  }

  onDrop(event: InsertEvent) {
    const componentType = (event.data as DragDataTransfer).type
    let componentId = (event.data as DragDataTransfer).id

    if (!componentId) {
      componentId = generateNewId(componentType)
    }

    const isCreation = !(componentId in this.items)

    const component = isCreation ? UIComponentFactory(componentType, componentId) : this.items[componentId]
    this.addItem({
      component,
      parent: this.root,
      index: event.index,
      select: !event.data.id,
    })
  }
}
