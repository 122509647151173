











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { component, ComponentOptions, OptionTypes } from '@/models/architecture'
import { ComponentType } from '@/models/data'

@Component
export default class OptionsDisplayer extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  readonly config!: ComponentOptions

  headers = [
    { text: 'Option', value: 'key' },
    { text: 'Nom', value: 'label' },
    { text: 'Type', value: 'type' },
    { text: 'Valeur par défaut', value: 'default' },
  ]

  isSelect(type: OptionTypes) {
    return type === OptionTypes.SELECT
  }

  isTabs(type: OptionTypes) {
    return type === OptionTypes.TABS
  }

  get myOptions() {
    return Object.entries(this.config).map(([key, value]) => {
      const fakeItem: component<any> = {
        id: '',
        name: '',
        components: [],
        type: ComponentType.CONTAINER,
        data: null,
        node: {},
      }
      return {
        // @ts-ignore
        ...value(fakeItem),
        key,
      }
    })
  }
}
