var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DropList',_vm._b({key:_vm.root,staticClass:"drop-zone",class:{
    empty: _vm.components && _vm.components.length === 0
  },style:(_vm.style),attrs:{"tag":_vm.tag,"row":_vm.direction === 'row',"column":_vm.direction === 'column',"items":_vm.components,"mode":_vm.dragData && _vm.dragData.id ? 'cut' : 'copy',"accepts-data":_vm.filterAccept,"no-animations":""},on:{"insert":_vm.onDrop,"reorder":_vm.onReorder},scopedSlots:_vm._u([_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),{key:"item",fn:function(ref){
  var item = ref.item;
return [_c('LayoutItem',{attrs:{"id":item,"root":_vm.root}})]}},{key:"feedback",fn:function(ref){
  var data = ref.data;
return [_c('div',{key:data.type,staticClass:"item feedback"},[_c('div',{staticClass:"text"},[_vm._v(" Déposer ici ")])])]}},{key:"reordering-feedback",fn:function(ref){
  var item = ref.item;
return [_c('div',{key:("reordering-feedback-" + item),staticClass:"item feedback"},[_c('div',{staticClass:"text"},[_vm._v(" Déposer ici ")])])]}}],null,true)},'DropList',_vm.$attrs,false),[_vm._l((Object.keys(_vm.$slots)),function(slot){return _vm._t(slot,null,{"slot":slot})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }