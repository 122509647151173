






import { Component } from 'vue-property-decorator'
import Input from '@/components/uiComponents/input'
import { UIComponent } from '@/components/uiComponents/ui-component'
import inputRender from '@/components/uiComponents/input/inputRender'

@Component({
  components: { inputRender },
})
export default class InputBuilder extends UIComponent<Input> {}
