

































































































































// TODO use https://vuetifyjs.com/en/components/data-tables/#data-tables

import { Vue, Component, Prop } from 'vue-property-decorator'
import { AutocompleteItem } from '@knitiv/api-client-javascript'

import Mf from '@/components/mf/mf.vue'
import { Right } from '@/models/node/right'

@Component({
  components: {
    Mf,
  },
})
export default class Rights extends Vue {
  @Prop({ type: Object, required: true })
  readonly value!: Record<string, Right>

  @Prop({ type: Boolean, required: false, default: false })
  readonly loading!: boolean

  @Prop({ type: String, required: false, default: 'Rôles' })
  readonly title!: string

  checked = true

  representation: AutocompleteItem | null = null

  isEditMode = false

  onSelect(item: AutocompleteItem) {
    this.representation = item
  }

  removeRepresentation(/* key: string */) {
    // const value = this.value
    //
    // this.$delete(value, key)
    //
    // this.$emit('input', value)
  }

  addRepresentation() {
    // const value = this.value
    //
    // if (this.representation) {
    //   this.$set(value, nanoid(), {
    //     value: this.text,
    //     _mode_: 'NORMAL',
    //     lang: {
    //       isa: this.representation.isa,
    //       kid: this.representation.kid,
    //       name: this.representation.name
    //     },
    //     type_kid: this.type
    //   })
    //
    //   this.representation = null
    //
    //   this.$emit('input', value)
    // }
  }
}
