import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'
import { createNumber } from '@/utils/options/creator'

export default class Column extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'Colonne'

  icon = 'mdi-view-column'

  category = Category.LAYOUT

  display = false

  options = {
    cols: createNumber('Taille sur téléphone', 12, 'Spécifie la taille que prends une colonne', {
      suffix: '/12',
    }),
    sm: createNumber('Taille sur tablette', 6, 'Spécifie la taille que prends une colonne', {
      suffix: '/12',
    }),
    md: createNumber('Taille sur laptop', 4, 'Spécifie la taille que prends une colonne', {
      suffix: '/12',
    }),
    lg: createNumber('Taille sur écran large', 3, 'Spécifie la taille que prends une colonne', {
      suffix: '/12',
    }),
    xl: createNumber('Taille sur écran très large', 2, 'Spécifie la taille que prends une colonne', {
      suffix: '/12',
    }),
  }
}
