


























import { Vue, Component } from 'vue-property-decorator'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import { ComponentOptions } from '@/models/architecture'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'

@Component({
  components: {
    JSONEditor,
    OptionsDisplayer,
  },
})
export default class RepreDev extends Vue {
  json = {
    Array: [1, 2, 3],
    Boolean: true,
    Null: null,
    Number: 123,
    Object: { a: 'b', c: 'd' },
    String: 'Hello World',
  }

  mode = 'code'

  options: ComponentOptions = {}

  mounted() {
    this.options = getDefinition(ComponentType.JSON_EDITOR).options
    console.log('this.options', this.options)
  }
}
