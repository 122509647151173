












import { Component, Watch } from 'vue-property-decorator'
import { Call } from 'vuex-pathify'
import { AutocompleteItem, UITree } from '@knitiv/api-client-javascript'

import { component } from '@/models/architecture'
import {
  AddComponentPayload,
  SetComponentsPayload,
} from '@/models/pages/pagebuilder'
import { UIComponent } from '@/components/uiComponents/ui-component'
import GroupLink from '@/components/uiComponents/group-link/index'

@Component({
  components: {},
})
export default class GroupLinkBuilder extends UIComponent<GroupLink> {
  link: string | null = null

  @Call('pagebuilder/mergeItemsRaw')
  mergeItemsRaw!: (items: Record<string, component<any>>) => void

  @Call('pagebuilder/addComponent')
  addComponent!: (payload: AddComponentPayload) => void

  @Call('pagebuilder/setComponents')
  setComponents!: (payload: SetComponentsPayload) => void

  @Watch('link', { immediate: true })
  async onLinkChanged() {
    const object = await this.$api.objectInfo(this.props.link)
    console.log('resolveLink', object)
    this.link = object.objname
  }

  // @Watch('link')
  async onComponentChange(link: AutocompleteItem[]) {
    const existingComponents = this.components
    // empty if existing
    if (existingComponents.length > 0) {
      this.setComponents({
        components: [],
        id: this.itemId,
      })
    }

    const newKid = link[0].kid

    const response = await this.$api.objectInfo({
      kid: newKid,
    })
    const json = JSON.parse(response.kjson) as UITree

    this.mergeItemsRaw(json.items)
    this.addComponent({
      parent: this.itemId,
      id: json.root,
      index: 0,
    })
  }
}
