




























































import { Vue, Component } from 'vue-property-decorator'
import Kid from '@/components/knitiv/kid.vue'
import JSONEditor from '@/components/knitiv/json-editor.vue'

@Component({
  components: {
    Kid,
    JSONEditor,
  },
})
export default class KidDev extends Vue {
  kid = ''
}
