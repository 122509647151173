import Vue, { CreateElement, VNode } from 'vue'
import Loader from '@/components/display/loader/loader.vue'

export default Vue.extend({
  functional: true,
  render(h: CreateElement): VNode {
    return h(Loader, {
      props: {
        type: 'image',
        data: {},
      },
    })
  },
})
