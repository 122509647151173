
import { Component, Vue } from 'vue-property-decorator'
import { listChildItems } from '@/components/uiComponents/renderUtils'

@Component({
  // @ts-ignore
  functional: true,
  // @ts-ignore
  render(createElement, { props }) {
    return createElement(
      'div',
      {
        class: 'kform-link',
      },
      listChildItems(props.rootItem, createElement),
    )
  },
})
export default class formLinkRender extends Vue {}
