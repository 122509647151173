import {
  PageBuilderSyncComponent,
} from '@/components/definitions/pageBuilderComponent'
import { Category } from '@/models/pages/pagebuilder'

export default class Card extends PageBuilderSyncComponent {
  loader = 'image'

  name = 'Carte'

  icon = 'mdi-card-bulleted-outline'

  category = Category.LAYOUT

  options = {}
}
