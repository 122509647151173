

















import { Component, Vue } from 'vue-property-decorator'
import Radio from '@/components/knitiv/radio.vue'
import JSONEditor from '@/components/knitiv/json-editor.vue'
import { getDefinition } from '@/components/definitions'
import { ComponentType } from '@/models/data'
import OptionsDisplayer from '@/components/optionsDisplayer.vue'
import { ComponentOptions } from '@/models/architecture'

@Component({
  components: {
    Radio,
    JSONEditor,
    OptionsDisplayer,
  },
})
export default class ButtonDev extends Vue {
  options: ComponentOptions = {}

  model = 'fred'

  items = [
    {
      name: 'Quentin',
      value: 'quentin',
    },
    {
      name: 'Fred',
      value: 'fred',
    },
    {
      name: 'Hamza',
      value: 'hamza',
    },
  ]

  mounted() {
    this.options = getDefinition(ComponentType.PANEL).options
    console.log('this.options', this.options)
  }
}
